import React from 'react';

import contactImage1 from '../images/pathway-5.jpg'
import contactImage2 from '../images/pathway-2.jpg'
import contactImage3 from '../images/pathway-6.jpg'
import contactImage4 from '../images/pathway-7.jpg'

const ImageGroup = props => {
  const { className, ...restProps } = props
  return (
    <div
      {...restProps}
      className={`${'contact-person-images' + (className ? ' ' + className : '')}`}
    >
      <div className="contact-image-col1">
        <div className="contact-image" style={{backgroundImage: `url(${contactImage1})`}}></div>
        <div className="contact-image dislocation-image" style={{backgroundImage: `url(${contactImage2})`}}></div>
      </div>
      <div className={"contact-image-col2"}>
        <div className="contact-image" style={{backgroundImage: `url(${contactImage3})`}}></div>
        <div className="contact-image" style={{backgroundImage: `url(${contactImage4})`}}></div>
      </div>
    </div>
  )
}

export default ImageGroup