import React from "react"
import { Link } from "gatsby"

import Layout from "../layout/layout"
import Button from "../components/button"
import ImageGroup from "../components/contactImageGroup"

const HowYouCanStudy = () => {
  return (
    <Layout
      title="How to study online"
      description="How to study online, learning tools, compleet asseessment tasks, practical work"
    >
      <div className="layout-container study-page-container">
        <div className="study-page-content">
          <h3 style={{ marginBottom: "20px" }}>Blended in person and online learning</h3>
          <div className="className=" study-page-p>
            <p>
            We have multiple locations, and a suite of online modules is available, and more are being developed all the time.
            </p>
            <br />
            <p>
              We know learners want different things so many modules have a
              smorgasbord of learning tools, resources and tips. You can pick
              and choose which you want to access.
            </p>
            <br />
            <p>
              You will have to do real work to complete assessment tasks and
              this work must be done in a workplace setting.
            </p>
            <br />
            <p>
              Some modules are theory-based but even then we don’t want you to
              regurgitate facts. We want you to think about how the theory
              applies in your practical work context and show us you have really
              got it!
            </p>
            <br />
          </div>
          <div className="hide-mobile">
            <Link to="/contact">
              <Button>Contact us</Button>
            </Link>
          </div>
        </div>
        <ImageGroup></ImageGroup>
        <div className="only-mobile">
          <Link to="/contact">
            <Button>Contact us</Button>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default HowYouCanStudy
